<template>
    <div class="s_box">
      <div class="share_title">
        <div class="tit">整箱运价详情</div>
        <div>
          <el-button type="primary" v-if="USER_INFO" style="background:#91BE42;border-color:#91BE42" size="small" @click="showDialog">一键复制</el-button>
          <el-button type="primary" style="background:#91BE42;border-color:#91BE42"  size="small" @click="handle_share_link" v-if='!hide_left'>分享链接</el-button>
          <!-- <el-button type="primary" v-if="hide_left&&USER_INFO&&USER_INFO.user_type==1" style="background:#91BE42;border-color:#91BE42"  size="small" @click="handle_dingchang">快速订舱</el-button> -->
        </div>
      </div>
      <shipMsg :header='header' pageType='share' v-loading="loading"></shipMsg>
      <div class="bottom_box">
          <div class="left" :class="{'hide_left_width':true}" >
            <div class="title">费用明细</div>
            <div style="padding:24px">
                <div class="unnoMsg" v-if="header.un_no">
                  <div>UNNO: <span class="color">{{ header.un_no | textFormat }}</span></div>
                  <div class="textOverflow" style="max-width: 360px;" :title="header.pscn | textFormat">PSN:{{ header.pscn | textFormat }}</div>
                  <div> Class:{{ header.classify | textFormat }} </div>
                  <div>PG:{{ header.packing_group | textFormat }}</div>
                </div>
                <div class="moneyList" v-loading="loading">
                  <div class="seaItem">
                    <div class="itemTitleBox">
                      <div>
                         <img src="https://ym-attached-file.oss-cn-shanghai.aliyuncs.com/miniProgram/ship2.png" style="width:30px;height:30px;margin-right:10px" alt="">
                        <span class="itemTitle">海运费：</span>
                        <span class="itemMOney"
                          >{{ header.currency||"USD" }}

                          <span
                            class="price"
                            >{{ seaTotal }}</span
                          ></span
                        ><span class="itemTips"></span>
                      </div>
                    </div>
                    <div class="itemContent">
                      <div class="tableLine tableTitle">
                        <div style="width:100%">
                          <div class="table1" style="width:25%"></div>
                          <div class="table2" style="width:25%">币种</div>
                          <div class="table3" style="width:25%">计量单位</div>
                          <div class="table4" style="width:25%">单价</div>
                        </div>

                      </div>
                      <div
                        class="tableLine"
                        v-for="(item, index) in header.container"
                        :key="index"
                      >
                        <div  v-if="item.number&&item.price!=0" style="width:100%">
                          <div class="table1" style="width:25%">海运费</div>
                          <div class="table2" style="width:25%">{{ item.code }}</div>
                          <div class="table3" style="width:25%">{{ item.name }}</div>
                          <div
                            style="width:25%"
                            class="table4"
                            :class="!header.is_open && !header.is_quote ? 'mohu' : 'price'"
                          >
                            {{ item.price }}
                          </div>
                        </div>

                      </div>
                    </div>
                  </div>
                  <costComp :costList='costList' @open_close="open_close"></costComp>
                </div>
            </div>
          </div>
          <!-- <div class="right" v-if="!hide_left">
              <div class="title">分享备注</div>
              <div style="padding:24px">
                <el-input
                  type="textarea"
                  :rows="4"
                  placeholder="输入客户信息，以便之后查询"
                  maxlength="48"
                  show-word-limit
                  v-model="textarea"
                >
                </el-input>
                <div style="text-align:right;margin-top:15px">
                    <el-button type="primary" size="mini" :loading="save_loading" style="background:#91BE42;border-color:#91BE42" @click="handleRemark">保存</el-button>
                </div>
                 <el-divider><span class="divider_span">最近分享记录（仅显示最近10条）</span></el-divider>
                <div class="r_list_box">
                    <div class="r_list_item" v-for="(item,i) in remarkArr.slice(0,10)" :key='i'>
                        <div class="msg">{{item.info}}</div>
                        <div class="tt">{{item.time_info | secondFormat("LL")   }}</div>
                    </div>
                </div>
              </div>
          </div> -->
          <div class="right" v-if="false">
              <div class="title">分享记录66</div>
              <div style="width: 500px;">
                <el-input 
                  size="small" 
                  v-model="share_form.name" 
                  class="kuinput"
                  placeholder="请输入客户名称"
                >
                </el-input>
                <el-input
                  type="textarea"
                  :rows="4"
                  placeholder="输入客户信息，以便之后查询"
                  maxlength="100"
                  show-word-limit
                  v-model="share_form.remarks"
                >
                </el-input>
                <div style="text-align:center;margin-top:15px">
                    <el-button type="primary" size="small" :loading="save_loading" style="width: 230px; background:#91BE42;border-color:#91BE42" @click="handleSave">保存</el-button>
                </div>
                 <!-- <el-divider><span class="divider_span">最近分享记录（仅显示最近10条）</span></el-divider>
                <div class="r_list_box">
                    <div class="r_list_item" v-for="(item,i) in remarkArr.slice(0,10)" :key='i'>
                        <div class="msg">{{item.info}}</div>
                        <div class="tt">{{item.time_info | secondFormat("LL")   }}</div>
                    </div>
                </div> -->
              </div>
          </div>
      </div>
      <div class="page_tips">
        <i class="el-icon-warning"></i>
        <span>订舱前请确认好准确的数据，订舱后因数据不对或其他任何原因退关都会产生空舱费。</span>
      </div>
      <el-dialog
        title="选择需要复制的内容"
        :visible.sync="copy_dialog"
        width="400px"
        :before-close="handleClose"
      >
        <div>
          <div class="chooseItem">
              <div class="itemtitle">基本信息</div>
              <div class="handleBox">
                <el-switch class="switch" v-model="check_base" active-color="#91be42" inactive-color="#ccc" ></el-switch>
              </div>
          </div>

          <div
            class="chooseItem"
            v-for="(item, index) in costList"
            :key="index"
          >
            <div class="itemtitle">{{ item.group_name }}</div>
            <div class="handleBox">
              <el-switch
                class="switch"
                v-model="item.canCopy"
                active-color="#91be42"
                inactive-color="#ccc"
              >
              </el-switch>
            </div>
          </div>

          <div class="chooseItem">
              <div class="itemtitle">订舱提示</div>
              <div class="handleBox">
                <el-switch class="switch" v-model="check_tips" active-color="#91be42" inactive-color="#ccc" ></el-switch>
              </div>
          </div>

        </div>
        <span slot="footer" class="dialog-footer">
          <el-button @click="handleClose" size="small"
            >取 消</el-button
          >
          <el-button type="primary" class="booking" size="small" @click="copy"
            >确 定</el-button
          >
        </span>
      </el-dialog>
    </div>
</template>
<script>
import shipMsg from "~bac/components/ymp/shipMsg";
import costComp from "~bac/components/ymp/costComp";
import { secondFormat } from "~/basePlugins/filters";
// import { copy } from '~bac/plugins/tools'
export default {
    data() {
        return{
            check_base:true,
            check_tips:true,
            copy_dialog:false,
            save_loading:false,
            seaTotal:null,
            header:{},
            remarkArr:[],
            costList:[],
            loading:false,
            textarea:"",
            share_form:{
              name:"",
              remarks:""
            },
            hide_left:false
        }
    },
    mounted(){
        this.getHeader()
        if(this.$route.query.type==1){// 添加一条访问记录
          let params={
            quotation_id:this.$route.query.id,
            tourist_id:this.$moment().unix() // 时间戳
          }
          if(this.USER_INFO&&this.USER_INFO.id){
            params.user_id=this.USER_INFO.id
          }
          this.$store.dispatch('API_freight/quotation_visit_save',params)
        }
    },
    components:{
        shipMsg,costComp
    },
    methods:{
        handle_dingchang(){
          this.$router.push({
            path: "/fclBookingSpace",
            query: {
              id: this.header.inquiry_id
            },
          });
        },
        open_close(e){
          this.costList[e.key].open=!this.costList[e.key].open
          this.costList=JSON.parse(JSON.stringify(this.costList))
        },
        changeShow(item){
            item.open = !item.open
            this.$forceUpdate()
        },
        // 复制链接
        handle_share_link(){
          this.$store.dispatch('API_freight/quotationIsShare',{quotation_id: this.$route.query.id})

          let value=window.location.href+'&type=1'
          const input = document.createElement("input");
          document.body.appendChild(input);
          input.value = value;
          input.select();
          if (document.execCommand("copy")) {
            document.execCommand("copy");
          }
          document.body.removeChild(input);
          this.$message.success("复制成功");
        },
        async handleSave(){
          if(!this.share_form.name){
            this.$message.warning("请输入客户名称")
            return
          }
          let arr=[]
          for(var item of this.header.container){
            if(item.number&&item.price!=0){
              arr.push(
                {
                  price:item.price,
                  code:"USD",
                  unit:item.name
                }
              )
            }
          }
          let params={
            freight_type:1,
            from_port:this.header.from_port_id,
            trans_port:this.header.trans_port_id||0,
            dest_port:this.header.dest_port_id,
            shipping_company:this.header.shipping_company_code,//船公司id
            historical_price:JSON.stringify(arr),//历史单价
            client:this.share_form.name,//客户
            sales_id:this.USER_INFO.id,//销售id
            remarks:this.share_form.remarks//备注
          }

        
          console.log('paramsparams',params);
          this.save_loading=true
          await this.$store.dispatch("API_freight/freight_share_save",params)
          this.share_form={
            name:"",
            remarks:""
          }
          this.save_loading=false
          this.$message.success("保存成功")
        },
        async handleRemark(){
            if(!this.textarea.length){
                return
            }
            this.save_loading=true
            let params={
                quotation_id: this.$route.query.id,
                remarks:this.textarea
            }
            await this.$store.dispatch("baseConsole/addBaseInfo",params)
            this.save_loading=false
            await this.getHeader()

            this.textarea=""
        },

        getDetail(){

        },
        async getHeader() {

            this.loading = true;
            let params = {
                quotation_id:this.$route.query.id
            };
            let result = await Promise.all([
                this.$store.dispatch("baseConsole/offer_info", params),
            ]);
            this.loading = false;
            if (result[0].success) {
                let data = result[0].data;

                if(!this.USER_INFO|| data.sales_info.sales_id!=this.USER_INFO.id ||this.$route.query.type==1){
                  this.hide_left=true
                }

                data.week = [];
                data.abs = Math.abs(data.price_lcl_dangerous);
                data.shipping_date_arr = data.shipping_date.split(",");
                data.shipping_date_arr.forEach((ee) => {
                if (ee == 1) {
                    data.week.push("周一");
                }
                if (ee == 2) {
                    data.week.push("周二");
                }
                if (ee == 3) {
                    data.week.push("周三");
                }
                if (ee == 4) {
                    data.week.push("周四");
                }
                if (ee == 5) {
                    data.week.push("周五");
                }
                if (ee == 6) {
                    data.week.push("周六");
                }
                if (ee == 7) {
                    data.week.push("周日");
                }
                });
                data.container = JSON.parse(data.container);
                let money = 0;
                data.container.forEach((ee) => {
                  money=Number(money).toFixed()
                  if (ee.number) {
                      money = money*1 + ee.price*1;
                  }
                });
                this.costList = JSON.parse(data.extend);

                this.costList.forEach((ee, index) => {
                ee.canCopy = true;
                ee.open = true;
                if (index > 0) {
                    ee.open = false;
                }
                });
                this.remarkArr=data.quotation_remarks
                this.seaTotal =money;
                this.header = data;
                this.effect_cost()
            }
        },
        effect_cost(){
          let checkedArr=[]
          for(var item of this.header.container){
            if(item.number && item.price!=0){
              checkedArr.push(item.name)
            }
          }
          for(var item of this.costList){
            item.group_total_price={}
            for(var it of item.data_list){
              if(it.unit == 5||it.measure=="GP"||it.measure=="箱"){ //---------------------------------------------箱

                for(var key of checkedArr){
                  if(key=='20GP'){
                    if(item.group_total_price[it.code]){
                      item.group_total_price[it.code]+=Number(it.GP20)
                    }else{
                      item.group_total_price[it.code]=Number(it.GP20)
                    }
                  }
                  if(key=='40GP'){
                    if(item.group_total_price[it.code]){
                      item.group_total_price[it.code]+=Number(it.GP40)
                    }else{
                      item.group_total_price[it.code]=Number(it.GP40)
                    }
                  }
                  if(key=="40HQ"){
                      if(item.group_total_price[it.code]){
                        item.group_total_price[it.code]+=Number(it.HC40)
                      }else{
                        item.group_total_price[it.code]=Number(it.HC40)
                      }
                  }
                }
              }else if(it.code==""){  // 没有币种得不计算

              }else{  // 票
                if(item.group_total_price[it.code]){
                  item.group_total_price[it.code]+=Number(it.price)
                }else{
                  item.group_total_price[it.code]=Number(it.price)
                }
              }
            }
          }
          this.costList=JSON.parse(JSON.stringify(this.costList))
        },
        handleClose() {
          this.copy_dialog=false
        },
        showDialog() {
          if (this.USER_INFO.company_bind_status != 0) {
            this.copy_dialog = true;
          } else {
            this.$router.push({
              path: "/console/company/basic",
              query: {},
            });
          }
        },
        copy() {
          var port =
            `${
              this.header.from_port_en || this.header.from_port_zh || "-"
            } - ${
              this.header.dest_port_en || this.header.dest_port_zh || "-"
            }` + "\n";

            if(this.check_base){
              if (this.header.trans_port_en) {
                port =
                  port +
                  `${
                    this.header.trans_port_en ||
                    this.header.trans_port_zh ||
                    "-"
                  } `;
              }
              port = port + (this.header.trans_port_en ? "转 " : "直达 ");
              port = port + "\n";
              var sea_str='海运费'
              for(var sea of  this.header.container){
                if(sea.number&&sea.price){
                  sea_str+=(sea.code+' '+sea.price+'/'+sea.name+'  ')
                }
              }
              port=port+sea_str+"\n"

              port =
              port + `船公司：${this.header.name_zh}  ，船期：`;
              this.header.week.forEach((ee, vv) => {
                port = port + ee;
                if (vv < this.header.week.length - 1) {
                  port = port + ",";
                }
              });
              port =port+`，航程：${this.header.days ? this.header.days : 0} 天，挂靠码头：${this.header.affiliated_terminal}，有效期：${ secondFormat(this.header.validity_start,'LL')}  -  ${ secondFormat(this.header.validity_end,'LL')}`
              port =port+'\n'+'备注'+this.header.remarks+"\n" 
            }

            

            let star = "";
            if (this.costList.length) {
              this.costList.forEach((res, index) => {
                if (res.data_list.length && res.canCopy) {
                  // star = star + "\n";
                  // star = star + "\n";
                  star = star + "=========================";
                  star = star + "\n";
                  star = star + "【" + res.group_name + "】" + "\n";
                  res.data_list.forEach((ee) => {
                    if (ee.unit == 5||ee.measure=="GP"||ee.measure=="箱") {
                      star =
                        star +
                        `-- ${ee.name_zh || ee.name_en || "-"}：${Number(
                          ee.GP20
                        ).toFixed(2)} ${ee.code}/20GP,${Number(ee.GP40).toFixed(2)} ${
                          ee.code
                        }/40GP,${Number(ee.HC40).toFixed(2)} ${ee.code}/40HQ`;

                      // if (ee.lowest_price) {
                      //   star = star + `,MINI：${ee.lowest_price}`;
                      // }
                      if (ee.remarks) {
                        star = star + `,*${ee.remarks}`;
                      }
                      star = star + "\n";
                    } else {
                      if((ee.price==0.01)&&ee.measure=='SET'){
                        star =star +
                        `-- ${ee.name_zh || ee.name_en || "-"}：`

                      }else if((ee.price==0.01)&&ee.measure!='SET'){
                        star =star +
                        `-- ${ee.name_zh || ee.name_en || "-"}：${ee.code}/${ee.measure} ,`;
                      }else{
                        star =star +
                        `-- ${ee.name_zh || ee.name_en || "-"}：${(ee.price==0.01)?0:Number(
                          ee.price
                        ).toFixed(2)} ${ee.code}/${ee.measure} ,`;
                      }

                      if (ee.lowest_price) {
                        star = star + `MINI:${ee.lowest_price}`;
                      }
                      if(ee.lowest_price && ee.remarks){
                        star = star + `,`
                      }
                      if (ee.remarks) {
                        star = star + `*${ee.remarks}`;
                      }
                      star = star + "\n";
                    }
                  });

                  if(res.plan_remarks_list.length){
                    star = star + "备注：";
                    res.plan_remarks_list.forEach(element => {
                        star = star+element+'\n'
                    });
                  }
                }
              });
            }

            let lastMsg = ""
            if(this.check_tips){
              lastMsg = "\n" + "订舱前请确认好准确的数据，订舱后因数据不对或其他任何原因退关都会产生空舱费。\n可自主登录运抵达（www.yundida.com）查询危拼运价。";
            }
              
            var content = port.concat(star, lastMsg);

            // 使用textarea支持换行，使用input不支持换行
            const textarea = document.createElement("textarea");
            textarea.value = content;
            document.body.appendChild(textarea);

            textarea.select();
            if (document.execCommand("copy")) {
              document.execCommand("copy");
              this.$message({
                message: "您选择的费用信息已经复制到剪贴板",
                type: "success",
              });
              this.dialogVisible = false;
            }
            document.body.removeChild(textarea);
            this.handleClose() // 关闭弹框
        },
      }
}
</script>
<style scoped lang='less'>
.hide_left_width{
  width: 1300px !important;
}
.s_box{
  width: 1300px;
  margin: auto;
}
.share_title{
  display: flex;
  align-items: center;
  justify-content: space-between;
  height:88px;
  .tit{
    font-size: 16px;
    font-weight: 800;
    color: #0C263F;
    line-height: 22px;
  }
}
.page_tips{
  margin:26px 0;
  display: flex;
  align-items: center;
  i{
    color: #FAAD14;
    font-size:30px;
    padding-right:9px;
  }
  span{
    height: 24px;
    font-size: 16px;
    font-weight: 500;
    color: rgba(0, 0, 0, 0.85);
    line-height: 24px;
  }
}
.bottom_box{
    border-top: 16px solid #FBFCFA;
    display: flex;
    justify-content: space-between;
    .title{
        height: 62px;
        padding-left: 24px;
        line-height: 62px;
        border-bottom:1px solid #f2f2f2;
        font-size: 16px;
        font-weight: 800;
        color: #0C263F;
    }
    .left{
        background: #fff;
        width: 796px;
    }
    .right{
        width: 488px;
        background: #fff;
        .tips{
            padding-bottom: 10px;
        }
        .r_list_item{
            border-bottom: 1px solid #f2f2f2;
            padding-bottom: 10px;
            .msg{
                padding: 10px 0;
                word-wrap: break-word;
                word-break: normal;
                font-size: 14px;
                font-weight: 400;
                color: #7C7C7C;
                line-height: 20px;
            }
            .tt{
                text-align: right;
                color: #8DB47F;
            }
        }
    }
    .right .r_list_item:last-child{
      border-bottom: 0;
    }
}

.header {
    background: #fff;
    margin-top: 16px;
    padding: 24px 0;
  .heraderTop {
    display: flex;
    justify-content: center;
    text-align: center;
    .div1 {
      // width: 300px;
      // text-align: right;
    }
    .div2 {
      max-width: 250px;
      margin: 0 20px;
    }
    .div3 {
      // width: 300px;
    }
    .portEn {
      color: #91be42;
      font-size: 24px;
      font-weight: 800;
    }
    .portZh {
      margin-top: 6px;
      font-size: 18px;
      color: rgba(0, 0, 0, 0.65);
    }
    .line {
      width: 150px;
      height: 1px;
      background: #cdcdcd;
      margin: 5px auto;
      position: relative;
      overflow: visible;
      .jiantou {
        position: absolute;
        color: #cdcdcd;
        right: -1px;
        top: -10px;
      }
    }
    .trans_port {
      color: #333333;
      font-size: 16px;
    }
    div {
      overflow: hidden;
      white-space: nowrap !important;
      text-overflow: ellipsis;
    }
  }
  .heraderButtom {
    width: 100%;
    margin-top: 24px;
    display: flex;
    padding-left: 50px;
    div {
      overflow: hidden;
      white-space: nowrap !important;
      text-overflow: ellipsis;
    }
    .div1 {
      width: 200px;
      color: rgba(0, 0, 0, 0.65);
    }
    .div2 {
      width: 200px;
      color: rgba(0, 0, 0, 0.65);
    }
    .div3 {
      width: 200px;
      color: rgba(0, 0, 0, 0.65);
    }
    .div4 {
      width: 300px;
      color: rgba(0, 0, 0, 0.65);
      overflow: visible;
      white-space: normal !important;
      text-overflow: ellipsis;
      word-break: break-all;
    }
    span {
      color: #298df8;
      margin-left: 4px;
    }
  }
}
.divider_span{
  font-size: 14px;
  font-weight: 400;
  color: #7C7C7C;
  line-height: 20px;
  display: inline-block;
  width: 220px;
  text-align: center;
}
.unnoMsg {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  div{
    margin-right: 40px;
    height: 20px;
    font-size: 14px;
    font-weight: 400;
    color: #3C5058;
    line-height: 20px;
  }
  .color{
    color: #91BE42;
  }
}
.moneyList {
  background: #fff;
  .seaItem {
    margin-bottom: 30px;
    .itemTitleBox {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .btn {
        font-size: 16px;
        color: #999;
        cursor: pointer;
      }
    }
    .itemTitleBox {
      padding-bottom: 20px;
    }
    .el-icon-ship {
      display: inline-block;
      width: 30px;
      height: 30px;
      background: #ff7727;
      border-radius: 50%;
      text-align: center;
      line-height: 30px;
      font-size: 16px;
      color: #fff;
      margin-right: 10px;
    }
    .itemTitle {
      font-weight: 900;
      font-size: 18px;
    }
    .itemMOney {
      font-weight: 900;
      font-size: 18px;
    }
    .itemTips {
      font-size: 16px;
      color: #ff7727;
      padding-left: 10px;
    }
    .tableLine {

      display: flex;
      align-items: center;


    }
    .tableLine>div{
      min-height: 60px;
      display:flex;
      padding: 13px 0;
      align-items: center;
      border-bottom:1px solid #EBEEF5;
    }
    .tableLine {
      .table1 {
        width: 250px;
      }
      .table2 {
        width: 220px;
      }
      .table3 {
        width: 220px;
      }
      .table4 {
        width: 220px;
      }
      .table5 {
        width: 220px;
      }
      .table6 {
        padding: 0;
        max-height:40px;
        text-align: center;
        overflow:hidden;
        text-overflow:ellipsis;
        display:-webkit-box;
        -webkit-box-orient:vertical;
        -webkit-line-clamp:2;
      }
    }
    .tableTitle {
      background: #fbfcfa;
      border-bottom: 1px solid #ebeef5;
    }
  }
}
.nodata{
  height: 100px;
  text-align: center;
  line-height: 100px;
  color: #606266;
}
.chooseItem {
  display: flex;
  justify-content: space-between;
  align-items: center;
  .itemtitle {
    font-size: 14px;
  }

  .switch {
    height: 50px;
  }
}
.kuinput{
  margin-bottom: 12px;
}
</style>
